<app-structured-data [json]="jsonLD"></app-structured-data>
<app-header></app-header>

<section id="header">
  <div class="overlay"></div>
  <div class="container" style="z-index: 3;">
    <div class="row">
      <div class="col-md-6 col-lg-6">

        <div class="text-wrapper">
          <h3 class="">Genalize</h3>
          <p>We help you bring ideas to life</p>
          <!-- <button class="btn btn-primary contact-us-btn">CONTACT US</button> -->
        </div>

      </div>
      <div class="col-md-6 col-lg-5">
        <div class="sign-up-form-wrap position-relative z-index shadow-lg rounded p-5 w-100 gray-light-bg">
          <div class="sign-up-form-header text-center mb-4">
              <h4 class="mb-0">Let's have a talk !</h4>
              <!-- <p>Get response within 24 hours</p> -->
          </div>
          <form class="login-signup-form" (ngSubmit)="f.form.valid && sendEmail($event, f)" #f="ngForm" novalidate>
              <div class="form-group">
                  <label class="hidden" for="from_name">Your Name</label>
                  <div class="input-group input-group-merge">
                      <div class="input-icon">
                          <span><i class="fas fa-user"></i></span>
                      </div>
                      <input id="from_name" name="from_name" type="text" #name="ngModel" [(ngModel)]="template_params.from_name" class="form-control" placeholder="Enter your name" required>
                    </div>
                        <div *ngIf="f.submitted && name.invalid" class="invalid-name">
                          <div *ngIf="name.errors.required">Name is required</div>
                        </div>
              </div>
              <div class="form-group">
                  <label class="hidden" for="from_email">Your Email</label>
                  <div class="input-group input-group-merge">
                      <div class="input-icon">
                          <span><i class="fas fa-envelope"></i></span>
                      </div>
                      <input id="from_email" name="from_email" type="email" #email="ngModel" [(ngModel)]="template_params.from_email" class="form-control" placeholder="Enter your email" required email>
                    </div>
                        <div *ngIf="f.submitted && email.invalid" class="invalid-email">
                          <div *ngIf="email.errors.required">Email is required</div>
                          <div *ngIf="email.errors.email">Email must be a valid email address</div>
                        </div>
              </div>
              <div class="form-group">
                  <label class="hidden" for="message_html">Write your message</label>
                  <div class="input-group input-group-merge">
                      <div class="input-icon textarea-icon">
                          <span><i class="fas fa-comment-alt"></i></span>
                      </div>
                      <textarea id="message_html" #message="ngModel" name="message_html" [(ngModel)]="template_params.message_html" class="form-control" placeholder="Write your message" cols="30" rows="4" required></textarea>
                    </div>
                        <div *ngIf="f.submitted && message.invalid" class="invalid-message">
                          <div *ngIf="message.errors.required">Message is required</div>
                        </div>
              </div>
              <!-- Submit -->
              <button class="btn btn-block solid-btn border-radius shadow mt-4 mb-3" type="submit">
                   <span *ngIf="!loading && !sent && !error">Send your query</span>
                   <span *ngIf="loading && !sent && !error">Sending...</span>
                   <span *ngIf="!loading && sent && !error"> <i class="fas fa-check"></i></span>
                   <span *ngIf="error">Sorry ! Could not send Query.</span>
              </button>
          </form>
      </div>
      </div>
    </div>
  </div>
  <div class="shape-bottom overflow-hidden">
    <img src="/assets/images/hero-shape.svg" alt="shape" class="bottom-shape" />
  </div>
</section>



<section id="about">
  <div class="container">
    <div class="short-about">
      <!-- <h3 class="title" style="padding-bottom: 10px;">About Us</h3> -->
      <img src="/assets/images/genalize-logo-sm.png" alt="Genalize Logo" style="max-height: 56px; margin-bottom: 34px;">

      <p>
        We are a team of professional developers who believe in customer
        centered design. Ranging from mobile to web, custom small scale
        solutions to scalable enterprise level big data solutions, we have all
        the skills you need to go fully digital.
      </p>
    </div>


  </div>
</section>

<section id="newtry">
  <div class="container">
    <h3 class="title">What We Offer</h3>

    <div class="new-item">
      <div class="img-holder">
        <img src="/assets/images/ai-com.jpg" alt="Artificial Intelligence"/>
      </div>
      <div class="detail-block">
        <h3>Big Data Solutions</h3>
        <p>
          Ever felt the need to manage realtime, large data in your enterprise ?
          If your answer is yes, we have it all covered within our stack. We use
          the best big data tools from the likes of AWS, GCP and Azure to make
          sure your data finds it's right value.
        </p>
        Our Services:
        <ul>
          <li>ETL services</li>
          <li>Realtime data visualization</li>
          <li>Distributed systems</li>
        </ul>
      </div>
    </div>

    <div class="new-item wrap-reverse">
      <div class="detail-block">
        <h3>Web Development / Automation</h3>
        <p>
          With the right blend of frontend and backend skills, we can produce
          the best web solutions to your organization. Another thing we focus on
          at Genalize is building highly scalable, and near realtime web
          crawlers and scrapers to get any data you need from the web.
        </p>
        Our Services:
        <ul>
          <li>Progressive Web Apps</li>
          <li>CMS Development</li>
          <li>Custom Dashboards</li>
          <li>Web Scraping</li>
          <li>Web Crawling</li>
        </ul>
      </div>
      <div class="img-holder">
        <img src="/assets/images/webdev-com.png" alt="Web Development/Automation" />
      </div>
    </div>

    <div class="new-item">
      <div class="img-holder">
        <img src="/assets/images/mobile-com.jpg" alt="Mobile"/>
      </div>
      <div class="detail-block">
        <h3>Mobile</h3>
        <p>
          With the number of mobile tech users increasing every year, the best
          way to reach your audience is to go mobile and now is the perfect
          time. We have an awesome group of mobile developers who can produce
          great cross platform mobile apps exactly the way you want.
        </p>
        Our Services:
        <ul>
          <li>Native App Development</li>
          <li>Cross Platform App Development</li>
        </ul>
      </div>
    </div>

    <div class="new-item wrap-reverse">
      <div class="detail-block">
        <h3>Digital Marketing</h3>
        <p>
          We provide custom solutions to drive traffic to your product using
          tried and tested AdTech and MarTech services. Not only relying on just
          the big players like Google, Facebook and LinkedIn for traffic, if you
          want more control, we have that covered as well.
        </p>
        Our Services:
        <ul>
          <li>Lead Generation</li>
          <li>Custom Pixel Tracking</li>
          <li>Google Marketing</li>
          <li>Facebook Marketing</li>
        </ul>
      </div>
      <div class="img-holder">
        <img src="/assets/images/digital-com.jpg" alt="Digital Marketing">
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
