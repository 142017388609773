<section id="navbar">
  <nav [ngClass]="{'navbar-light': scrolled}" class="navbar navbar-expand-lg fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" aria-label="Genalize" href="#">
        <!-- <img src="/assets/images/genalize-logo.png" alt="" style="height: 75px;"> -->
      </a>
      <button class="navbar-toggler"
      (click)="toggleCollapsed()"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation">
        <span class=""><i class="fa fa-bars"></i></span>
      </button>
      <div class="justify-content-end" id="navbarNavAltMarkup" [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}">
        <div class="navbar-nav pull-right">
          <a class="nav-item nav-link active" href="{{is_portfolio_page? '/': '#header'}}">Home <span class="sr-only">(current)</span></a>
          <a class="nav-item nav-link" href="{{is_portfolio_page? '/#about': '#about'}}">About</a>
          <a routerLink="/portfolio" class="nav-item nav-link">Portfolio</a>
          <a class="nav-item nav-link" href="{{is_portfolio_page? '/#contact': '#contact'}}">Contact</a>
        </div>
      </div>
    </div>
  </nav>
</section>
