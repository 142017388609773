<section id="footer">
  <h3>Genalize </h3>
  <p>All Rights Reserved</p>
  <ul>
    <li><a aria-label="Facebook"  target="_blank" rel="noopener" class="facebook" href="https://www.facebook.com/genalizer"><i class="fab fa-facebook"></i></a></li>
    <li><a aria-label="Twitter"  target="_blank" rel="noopener" class="twitter" href="https://twitter.com/GenalizeT"><i class="fab fa-twitter"></i></a></li>
    <li><a aria-label="Github"  target="_blank" rel="noopener" class="github" href="https://github.com/Genalize"><i class="fab fa-github"></i></a></li>
    <li><a aria-label="LinkedIn"  target="_blank" rel="noopener" class="linkedin" href="https://www.linkedin.com/company/genalize/"><i class="fab fa-linkedin"></i></a></li>
  </ul>
</section>
