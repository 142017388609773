import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { Meta } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';

declare const window: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  data = {
    title: 'Genalize',
    // tslint:disable-next-line: max-line-length
    description: 'We are a team of professional developers who believe in customer centered design. Ranging from mobile to web, custom small scale solutions to scalable enterprise level big data solutions, we have all the skills you need to go fully digital.',
    // tslint:disable-next-line:max-line-length
    image: 'https://genalize.com.np/assets/images/genalize.jpg'
  };
  jsonLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'Genalize',
    url: 'https://genalize.com.np',
    sameAs: [
      'https://www.linkedin.com/company/genalize/',
    ]
  };
  loading = false;
  sent = false;
  error = false;
  // tslint:disable-next-line: variable-name
  template_params = {
    from_name: '',
    from_email: '',
    message_html: ''
  };
  constructor(private readonly meta: Meta) { }

  ngOnInit() {
    this.updateMetaTags();
  }

  public sendEmail(e: Event, form: NgForm) {
    e.preventDefault();
    this.loading = true;
    emailjs.sendForm('default_service', 'template_pJRAx9Mt_clone', e.target as HTMLFormElement, 'user_beYA7QXa39FfHld3W1J4a')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
        this.loading = false;
        this.sent = true;
        form.resetForm();
      }, (error) => {
        console.log(error.text);
        this.error = true;
      });
  }
  resetForm() {
    this.template_params.from_name = '';
    this.template_params.from_email = '';
    this.template_params.message_html = '';
    this.error = false;
  }

  updateMetaTags() {
    this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ property: 'og:url', content: 'https://genalize.com.np' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Genalize' });
    this.meta.updateTag({ property: 'og:article:author', content: 'Genalize' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:image', content: this.data.image });
    this.meta.updateTag({ property: 'og:image:secure_url', content: this.data.image });
    this.meta.updateTag({ property: 'og:image:alt', content: 'Genalize' });
    this.meta.updateTag({ property: 'og:title', content: this.data.title });
    this.meta.updateTag({ property: 'og:description', content: this.data.description });
    this.meta.updateTag({ property: 'twitter:description', content: this.data.description });
    this.meta.updateTag({ property: 'twitter:title', content: this.data.title });
    this.meta.updateTag({ property: 'twitter:site', content: 'Genalize' });
    this.meta.updateTag({ property: 'twitter:image', content: this.data.image });
    this.meta.updateTag({ property: 'twitter:creator', content: '@GenalizeT' });
    this.meta.updateTag({ name: 'author', content: 'Genalize' });
  }

}
