import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-structured-data',
  templateUrl: './structured-data.component.html',
  styleUrls: ['./structured-data.component.scss']
})
export class StructuredDataComponent implements OnInit {
  @Input() json: {[key: string]: any};
  html: SafeHtml;
  constructor(private readonly sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.html = this.getSafeHTML(this.json);
  }
  getSafeHTML(jsonLD: {[key: string]: any}): SafeHtml {
    // escape / to prevent script tag in JSON
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    const html = `<script type="application/ld+json">${json}</script>`;

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
