import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  scrolled = false;
  collapsed = true;

  is_portfolio_page = false;
  constructor(public router: Router) { }
  ngOnInit() {
    if (this.router.url.indexOf('/portfolio') > -1) {
      this.is_portfolio_page = true;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      console.log('scrolled')
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

}
