import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import * as webfont from 'webfontloader';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'genalize-new';

  constructor(@Inject(PLATFORM_ID) private platformId: string) {

  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      webfont.load({
        google: {
          families: [
            'Montserrat:300,400,500,600,700',
            'Open+Sans:300,400&display=swap'
          ]
        }
      });
    }
  }

}

