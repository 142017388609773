import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  data = {
    title: 'Genalize',
    // tslint:disable-next-line: max-line-length
    description: 'We are a team of professional developers who believe in customer centered design. Ranging from mobile to web, custom small scale solutions to scalable enterprise level big data solutions, we have all the skills you need to go fully digital.',
    // tslint:disable-next-line:max-line-length
    image: 'https://genalize.com.np/assets/images/portfolio.jpg'
  };
  constructor(private readonly meta: Meta,
              private readonly title: Title) { }

  ngOnInit() {
    this.title.setTitle('Genalize - Portfolio');
    this.updateMetaTags();
  }

  updateMetaTags() {
    this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ property: 'og:url', content: 'https://genalize.com.np/portfolio' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Genalize' });
    this.meta.updateTag({ property: 'og:article:author', content: 'Genalize' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:image', content: this.data.image });
    this.meta.updateTag({ property: 'og:image:secure_url', content: this.data.image });
    this.meta.updateTag({ property: 'og:image:alt', content: 'Genalize' });
    this.meta.updateTag({ property: 'og:title', content: this.data.title });
    this.meta.updateTag({ property: 'og:description', content: this.data.description });
    this.meta.updateTag({ property: 'twitter:description', content: this.data.description });
    this.meta.updateTag({ property: 'twitter:title', content: this.data.title });
    this.meta.updateTag({ property: 'twitter:site', content: 'Genalize' });
    this.meta.updateTag({ property: 'twitter:image', content: this.data.image });
    this.meta.updateTag({ property: 'twitter:creator', content: '@GenalizeT' });
    this.meta.updateTag({ name: 'author', content: 'Genalize' });
  }
}
