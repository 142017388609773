<app-header></app-header>
<section id="header">

  <div class="overlay"></div>
  <div class="container" style="z-index: 2">

    <div class="text-wrapper">
      <h3 class="">Portfolio</h3>
    </div>
  </div>
</section>

<section id="newtry">
  <div class="container">
    <!--<h3 class="title"></h3>-->

    <div class="new-item">
      <div class="img-holder">
        <img src="/assets/images/portfolio_images/hire1.png"/>
        <img src="/assets/images/portfolio_images/hire2.png"/>
      </div>
      <div class="detail-block">
        <h3>Intelligent Job Resume Matching System (IJorms)</h3>
        <p>IJorms is an intelligent software built for the HR department of an enterprise.
            It uses machine learning to extract features from the resumes of the candidates 
            who applied for a job. 
            Then it uses dynamic rule based ranking algorithm to rank the resumes according to the
            job description.   
        </p>
        <a class="view-product-btn" target="_blank" href="https://github.com/Genalize/iJorms">View Product <i class="fa fa-angle-right"></i></a>
      </div>
    </div>

    <div class="new-item wrap-reverse">
      <div class="detail-block">
        <h3>Predict Which Horse Wins</h3>
        <p>This program combines the power of realtime web crawling and sentiment analysis to predict the 
            outcome of a horse race. It crawls from <a href="https://www.attheraces.com">AtTheRaces</a> website periodically, the data on the attributes
            of the horse and the rider and at the same time looks up in Twitter to see what people are tweeting 
            about the horse. Finally, it finds the sentiment of the respective tweets to see which horse has the 
            most positive tweets.
        </p>
        <a class="view-product-btn" target="_blank" href="https://github.com/Genalize/predictWhichHorseWins">View Product <i class="fa fa-angle-right"></i></a>
      </div>
      <div class="img-holder">
            <img src="/assets/images/portfolio_images/horserace1.png"/>
            <img src="/assets/images/portfolio_images/horserace2.png"/>
        </div>
    </div>

    <div class="new-item">
      <div class="img-holder">
        <img src="/assets/images/portfolio_images/linkedin1.png"/>
        <img src="/assets/images/portfolio_images/linkedin2.png"/>
      </div>
      <div class="detail-block">
        <h3>LinkedIn Scraper</h3>
        <p>Given the url of a LinkedIn profile, this program scrapes all data from the profile, 
            including his personal details, connections, posts, articles made by him and the comments
            in those posts. It has been proved useful when it comes to retrieving data about a user
            in combination with other social networking platform like Facebook and Twitter.
        </p>
        <a class="view-product-btn" target="_blank" href="https://github.com/Genalize/LinkedInScraper">View Product <i class="fa fa-angle-right"></i></a>
      </div>
    </div>

    <div class="new-item wrap-reverse">
      <div class="detail-block">
        <h3>Movie Recommendation Engine</h3>
        <p>Using the movie dataset from the movielens, this program uses SGD and Matrix Factorization
            to find similar movies according to their latent features. It helps the users see the movies
            they may be interested in based on their past preferences. 
        </p>
        <a class="view-product-btn" target="_blank" href="https://github.com/Genalize/movieRecommendations">View Product <i class="fa fa-angle-right"></i></a>
      </div>
      <div class="img-holder">
        <img src="/assets/images/portfolio_images/movie1.png"/>
        <img src="/assets/images/portfolio_images/movie2.png"/>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
